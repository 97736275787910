export default {
  name: "card",
  props: {
    item: {
      type: Object,
      default: () => {
      }
    },

  },
  data() {
    return {}
  },
  computed: {},
  created() {
  },
  methods: {},

}
